<!-- 选择礼品 -->
<!--  source: 0: 从活动中选礼品 1: 从资料库中选礼品 -->
<template>
  <vh-dialog
    title="选择礼品"
    top="10vh"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :before-close="handleClose"
    custom-class="vhc-dialog vhc-dialog--choose-prize"
    width="660px"
  >
    <div class="vhc-dialog-warp" v-loading="!isInited && loading">
      <div class="vhc-dialog-main" v-if="isInited">
        <!-- 没有数据时的展示 -->
        <div class="vhc-dialog-empty" v-if="isEmpty">
          <noData :nullType="'nullData'" :text="'您还没有礼品，快来创建吧~'">
            <vh-button type="primary" round @click="handleAdd" v-preventReClick>创建</vh-button>
          </noData>
        </div>
        <vh-scrollbar style="height: 100%" ref="vhScrollbar" v-loadMore="moreLoadData">
          <div class="vhc-dialog-content" v-if="!isEmpty">
            <!-- 头部 -->
            <div class="vhc-dialog-content__hd">
              <vh-button size="medium" type="primary" round @click="handleAdd">创建礼品</vh-button>
              <vh-input
                round
                class="search-input"
                placeholder="请输入礼品名称"
                v-model="keyword"
                clearable
                v-clearEmoij
                @clear="handleSearch"
                @keyup.enter.native="handleSearch"
              >
                <i class="vh-icon-search vh-input__icon" slot="prefix" @click="handleSearch"></i>
              </vh-input>
            </div>
            <!-- 内容区 -->
            <div class="vhc-dialog-content__bd">
              <vh-table
                ref="tableList"
                :data="dataList"
                v-loading="loading"
                height="300px"
                @selection-change="handleSelectionChange"
                @cell-mouse-enter="handleCellMouseEnter"
                @cell-mouse-leave="handleCellMouseLeave"
                @select-all="checkAllRow"
              >
                <template>
                  <vh-table-column
                    v-if="mode === 'multiple'"
                    type="selection"
                    width="55"
                    key="prize_select_1"
                  ></vh-table-column>
                  <vh-table-column v-else fixed width="55" class-name="section-td">
                    <template slot-scope="scope">
                      <vh-radio v-model="selectedId" :label="scope.row.prize_id">&nbsp;</vh-radio>
                    </template>
                  </vh-table-column>
                </template>

                <vh-table-column prop="img_path" label="礼品图片" width="100">
                  <template slot-scope="scope">
                    <div class="image-preview">
                      <vh-image
                        style="height: 40px"
                        :src="scope.row.img_path"
                        :preview-src-list="[scope.row.img_path]"
                      ></vh-image>
                    </div>
                  </template>
                </vh-table-column>
                <vh-table-column prop="prize_name" label="礼品标题" width="140">
                  <template slot-scope="scope">
                    <vh-tooltip
                      :disabled="!isTextOverflow"
                      effect="dark"
                      placement="top-start"
                      :content="scope.row.prize_name"
                    >
                      <div class="custom-tooltip-content">{{ scope.row.prize_name }}</div>
                    </vh-tooltip>
                  </template>
                </vh-table-column>
                <vh-table-column prop="prize_desc" label="礼品描述">
                  <template slot-scope="scope">
                    <vh-tooltip
                      :disabled="!isTextOverflow"
                      effect="dark"
                      placement="top-start"
                      :content="scope.row.prize_desc"
                    >
                      <div class="custom-tooltip-content">{{ scope.row.prize_desc }}</div>
                    </vh-tooltip>
                  </template>
                </vh-table-column>
              </vh-table>
            </div>
          </div>
        </vh-scrollbar>
      </div>
    </div>
    <!-- 底部操作栏 -->
    <div slot="footer" class="vhc-dialog__ft">
      <div class="op-bar">
        <div class="select-text-tip">
          当前选中
          <span class="select-number">{{ selectedNum }}</span>
          个礼品
        </div>
        <div class="op-box">
          <vh-button type="info" size="medium" round plain @click="handleClose">取消</vh-button>
          <vh-button
            size="medium"
            type="primary"
            round
            @click="handleSubmit"
            :disabled="selectedNum < 1"
          >
            确定
          </vh-button>
        </div>
      </div>
    </div>
  </vh-dialog>
</template>
<script>
  import noData from '@/views/PlatformModule/Error/nullPage';
  import to from 'await-to-js';
  import tableCellTooltip from '@/components/TableList/mixins/tableCellTooltip';

  export default {
    name: 'ChooseReward',
    mixins: [tableCellTooltip],
    components: {
      noData
    },
    props: {
      // 数据来源
      source: {
        type: Number,
        default: 1 // 0: 从活动中选礼品 1: 从资料库中选礼品
      },
      webinarId: {
        type: [Number, String],
        default: '' // 活动id
      },
      roomId: {
        type: [Number, String],
        default: '' // 房间id
      },
      // 可选最大数量，数量=1时属于单选，>1时是多选
      maxCount: {
        type: Number,
        default: 1,
        required: true,
        validator: function (value) {
          return value > 0;
        }
      }
    },
    data() {
      return {
        dialogVisible: false,

        isInited: false, // 是否有成功的请求
        loading: false,
        isEmpty: true, // 是否空数据

        selectedId: '', // 单选时选中的礼品的id
        multipleSelection: [], // 多选时选中的礼品数组

        keyword: '',
        dataList: [],
        total: 0,
        // 分页参数
        pos: 0,
        limit: 10,
        isCheckAll: false
      };
    },
    computed: {
      mode() {
        return this.maxCount === 1 ? 'single' : 'multiple';
      },
      selectedNum() {
        if (this.mode === 'single') {
          return this.selectedId ? 1 : 0;
        }
        return this.multipleSelection.length;
      }
    },
    watch: {
      dialogVisible() {
        if (this.dialogVisible) {
          this.isInited = false;
          this.loading = false;
          this.isCheckAll = false;
          this.isEmpty = true;
          this.dataList = [];
          this.selectedId = '';
          this.multipleSelection = [];
          this.keyword = '';
          this.pos = 0;
          this.limit = 10;
          this.fetchData();
        } else {
          this.dataList = [];
          this.multipleSelection = [];
          try {
            this.$refs.tableList.clearSelection();
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    methods: {
      checkAllRow(selection) {
        console.log('全选与非全选', selection);
        // 只要数量大于0，即是够了全选
        this.isCheckAll = selection && selection.length > 0;
      },
      handleClose() {
        this.dialogVisible = false;
      },
      handleAdd() {
        this.dialogVisible = false;
        this.$emit('before-add');
      },
      // 搜索
      handleSearch() {
        this.pos = 0;
        this.dataList = [];
        this.fetchData();
      },
      // 全选/部分选中/取消/部分取消时触发
      handleSelectionChange(val) {
        console.log(val);
        this.multipleSelection = val;
      },
      moreLoadData() {
        console.log('----moreLoadData---');
        if (this.dataList.length >= this.total) return;
        this.pos = this.dataList.length;
        this.fetchData();
      },
      // 确定
      handleSubmit() {
        if (this.mode === 'multiple' && this.multipleSelection.length > this.maxCount) {
          this.$vhMessage.error(`最多只能选择${this.maxCount}个礼品`);
          return;
        }
        if (this.mode === 'single' && this.selectedId) {
          const item = this.dataList.find(item => item.prize_id === this.selectedId);
          this.$emit('completeSelection', item ? [item] : []);
        } else if (this.mode === 'multiple' && this.multipleSelection.length) {
          this.$emit('completeSelection', this.multipleSelection);
        }
      },
      async fetchData() {
        this.loading = true;
        this.keyword = this.keyword.trim();
        const params = {
          limit: this.limit,
          pos: this.pos,
          keyword: this.keyword,
          source: this.source, // 0:直播间(默认) 1:资料管理 当source为0时房间id必填,当source为1时房间id必不填
          roomId: this.roomId,
          award_type: 0 // 礼品类型，0-自定义礼品 1-优惠券礼品 2-礼品，默认0
        };
        // http://yapi.vhall.domain/project/101/interface/api/54699
        const [err, res] = await to(this.$fetch('getPrizeList', params));
        this.loading = false;
        if (err || !res || res.code != 200) {
          this.$message.error(err.msg || res?.msg || '请求数据失败');
          return;
        }
        this.isInited = true;
        // 资料库的总数是count, 礼品列表的总数是total
        this.total = res.data.count || res.data.total || 0;
        this.isEmpty = !this.total && this.keyword.length === 0;
        this.dataList.push(...(res.data.list || []));
        if (this.isCheckAll) {
          this.$refs.tableList.toggleAllSelection();
        }
      }
    }
  };
</script>
<style>
  .vhc-dialog--choose-prize {
    min-height: 40vh;
    max-height: 80vh;
  }
</style>
