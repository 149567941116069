<!-- 条件与礼品设置 -->
<template>
  <div class="prize-condition-set">
    <vh-form
      :model="form"
      :rules="rules"
      ref="prizeForm"
      label-width="80px"
      onsubmit="return false;"
    >
      <vh-form-item label="标题" align="left" prop="title" label-width="112px" required>
        <VhallInput
          v-model="form.title"
          autocomplete="off"
          :maxlength="100"
          placeholder="请输入标题"
          show-word-limit
        ></VhallInput>
      </vh-form-item>

      <vh-form-item label="任务条件" label-width="112px" required>
        <p class="text-mute">
          观看时长达到指定时间则有机会中奖，观看时长定义：观众在直播间的连续时长，观众退出直播间重新进入或刷新直播间，观看时长将重新统计
        </p>
        <vh-radio-group v-model="form.watch_minute">
          <vh-radio :label="5">5分钟</vh-radio>
          <vh-radio :label="10">10分钟</vh-radio>
          <vh-radio :label="15">15分钟</vh-radio>
          <vh-radio :label="-1">
            <vh-form-item prop="watch_minute" style="display: inline-block">
              <vh-input
                placeholder="1-300"
                type="number"
                v-model="custom_minute"
                style="width: 110px"
                class="inline_input"
              >
                <template slot="append">分钟</template>
              </vh-input>
            </vh-form-item>
          </vh-radio>
        </vh-radio-group>
      </vh-form-item>
      <vh-form-item label="中奖规则" label-width="112px" required>
        <vh-form-item style="display: inline-block" prop="winner_rule_cardinal">
          <vh-input class="inline_input" type="number" v-model="form.winner_rule_cardinal">
            <template slot="append">人</template>
          </vh-input>
        </vh-form-item>
        完成任务可有
        <vh-form-item style="display: inline-block" prop="winner_rule_hit">
          <vh-input class="inline_input" type="number" v-model="form.winner_rule_hit">
            <template slot="append">人</template>
          </vh-input>
        </vh-form-item>
        中奖
        <vh-tooltip effect="dark" placement="right" v-tooltipMove>
          <div slot="content">
            1.建议根据礼品数量填写中奖规则
            <br />
            2.设置多个礼品时，礼品随机发放
          </div>
          <i class="iconfont-v3 saasicon_help_m"></i>
        </vh-tooltip>
      </vh-form-item>
      <vh-form-item label="展示效果" label-width="112px" required>
        <p class="text-mute">设置中奖动效在手机观看端展示位置</p>
        <vh-radio-group v-model="form.display_of_results">
          <vh-radio :label="0" default>全屏展示</vh-radio>
          <vh-radio :label="1">半屏展示</vh-radio>
        </vh-radio-group>
      </vh-form-item>

      <vh-form-item label-width="35px">
        <vh-button
          type="primary"
          size="medium"
          round
          class="btn-save"
          :loading="loading"
          :disabled="status < 1 || !form.title"
          v-preventReClick
          @click="handleSubmit"
        >
          保存
        </vh-button>
      </vh-form-item>
    </vh-form>
  </div>
</template>
<script>
  import tableCellTooltip from '@/components/TableList/mixins/tableCellTooltip';
  import ChoiceButton from '@/components/ChoiceButton/index.vue';
  import to from 'await-to-js';
  import { sessionOrLocal } from '@/utils/utils';

  export default {
    name: 'PrizeConditionSet',
    mixins: [tableCellTooltip],
    components: {
      ChoiceButton
    },
    inject: ['sharedDataFun', 'updateSharedDataFun'],
    data() {
      return {
        userId: '',
        loading: false,
        status: -1, // -1初始，0获取数据有错误,1获取数据成功
        form: {
          title: '',
          watch_minute: 15,
          winner_rule_cardinal: 10,
          winner_rule_hit: 1,
          display_of_results: 0 // 展示效果，0-全屏（默认） 1-半屏
        },
        custom_minute: '',
        rules: {
          title: [{ required: true, message: '请输入标题', trigger: ['blur', 'change'] }],

          watch_minute: [
            {
              validator: (rule, val, callback) => {
                const reg = /^\d+$/;
                if (
                  val == -1 &&
                  (!reg.test(this.custom_minute) ||
                    parseInt(this.custom_minute) < 1 ||
                    parseInt(this.custom_minute) > 300)
                ) {
                  return callback(new Error('请输入1-300整数'));
                }
                callback();
              },
              trigger: 'blur'
            }
          ],
          winner_rule_cardinal: [
            { required: true, message: '请输入人数', trigger: ['blur'] },
            {
              validator: (rule, val, callback) => {
                const reg = /^\d+$/;
                if (!reg.test(val) || parseInt(val, 10) < 1) {
                  return callback(new Error('请输入正整数'));
                }
                if (
                  this.form.winner_rule_hit !== '' &&
                  Number(this.form.winner_rule_hit) > Number(val)
                ) {
                  return callback(new Error('中奖人数不能大于完成人数'));
                }
                callback();
              },
              trigger: 'blur'
            }
          ],
          winner_rule_hit: [
            { required: true, message: '请输入人数', trigger: ['blur'] },
            {
              validator: (rule, val, callback) => {
                const reg = /^\d+$/;
                if (!reg.test(val) || parseInt(val, 10) < 1) {
                  return callback(new Error('请输入正整数'));
                }

                if (
                  this.form.winner_rule_cardinal !== '' &&
                  Number(this.form.winner_rule_cardinal) < Number(val)
                ) {
                  return callback(new Error('中奖人数不能大于完成人数'));
                }
                callback();
              },
              trigger: 'blur'
            }
          ]
        },

        hasWatchRewardAuth: false, //是否观看有礼权限
        hasQuestionnaireAuth: false, //是否问卷有权限
        hasPrizeAuth: false, //是否抽奖有权限

        oriQuestionIds: [], //最原始的已绑定的问卷id列表
        questionnaireList: [], // 选择的问卷列表
        prizeList: [], // 选择的礼品列表
        isSubmit: false // 是否提交了
      };
    },
    computed: {
      sharedData() {
        return this.sharedDataFun();
      },
      // 从对话框选中的的问卷的id集合,
      questionIds() {
        return this.questionnaireList.map(item => item.question_id);
      }
    },
    created() {
      this.userId = JSON.parse(sessionOrLocal.get('userId'));
    },
    methods: {
      beforePrizeAdd() {
        // 延迟打开，避免上个对话框关闭时闪烁
        setTimeout(() => {
          this.$refs.addReward.reset();
          this.$refs.addReward.dialogVisible = true;
        }, 300);
      },
      async fetchData() {
        this.status == -1;
        await Promise.all([this.fetchPermissions(), this.fetchBaseData()]);
        if (this.status == -1) {
          this.status = 1;
        }
      },
      // 获取权限
      async fetchPermissions() {
        const params = {
          webinar_id: this.sharedData.webinarId,
          webinar_user_id: this.userId,
          scene_id: 1
        };
        const [err, res] = await to(this.$fetch('planFunctionGet', params));
        if (err || res?.code != 200) {
          this.$message.error(err?.msg || res?.msg || '获取权限失败');
          this.status = 0;
          return;
        }
        const permissions = JSON.parse(res.data.permissions);
        // 是否有观看有礼权限
        this.hasWatchRewardAuth = permissions['watch_gift'] == 1 || true;

        if (!this.hasWatchRewardAuth) {
          this.$vhMessage.error('未开通观看有礼的权限');
          this.status = 0;
        }
      },
      // 获取互动ID获取基础数据（复制和编辑共用）
      async fetchBaseData() {
        if (!this.sharedData.watchRewardId) return;
        const params = {
          webinar_id: this.sharedData.webinarId,
          id: this.sharedData.watchRewardId
        };
        const [err, res] = await to(this.$fetch('getWatchReward', params));
        if (err || res?.code != 200) {
          this.$message.error(err?.msg || res?.msg || '获取数据失败');
          this.status = 0;
          return;
        }
        const { title, display_of_results, winner_rule_cardinal, winner_rule_hit, watch_minute } =
          res.data;
        this.form.title = title;
        this.form.display_of_results = display_of_results;
        this.form.winner_rule_cardinal = winner_rule_cardinal;
        this.form.winner_rule_hit = winner_rule_hit;
        if ([5, 10, 15].includes(watch_minute)) {
          this.form.watch_minute = watch_minute;
        } else {
          this.form.watch_minute = -1;
          this.custom_minute = watch_minute;
        }
      },
      //【观看有礼】"条件与礼品设置" 保存
      async handleSubmit() {
        this.isSubmit = true;
        if (!this.hasWatchRewardAuth) {
          this.$vhMessage.error('您需要先开通『观看有礼』的权限');
          return;
        }
        const valid = await this.$refs['prizeForm'].validate().catch(() => false);
        if (!valid) return;

        const params = {
          webinar_id: this.sharedData.webinarId,
          ...this.form,
          id: this.$route.query.wrId
        };
        params.title = this.$escapeHTML(params.title);
        ![5, 10, 15].includes(params.watch_minute) && (params.watch_minute = this.custom_minute);
        if (this.sharedData.watchRewardId) {
          params.id = this.sharedData.watchRewardId; //有观看有礼id走编辑，否则走创建
        }
        // 提交数据
        this.loading = true;
        const [err, res] = params.id
          ? await to(this.$fetch('editWatchReward', params))
          : await to(this.$fetch('saveWatchReward', params));
        this.loading = false;
        if (err || !res || res.code != 200) {
          this.$message.error(err.msg || res?.msg || '请求出现异常');
          return;
        }
        if (!this.sharedData.watchRewardId) {
          // 复制或者新增完成，跳转到编辑模式
          const watchRewardId = res?.data?.id || this.$route.query.wrId;
          this.$router.push({
            path: `/live/WatchRewardSetting/${this.sharedData.webinarId}`,
            query: {
              roomId: this.$route.query.roomId,
              wrId: watchRewardId
            }
          });
          this.updateSharedDataFun(watchRewardId);
        }
        this.$vhMessage.success('保存成功');
      }
    },
    mounted() {
      this.fetchData();
    }
  };
</script>
<style lang="less" scoped>
  .prize-condition-set {
    max-width: 680px;
    margin-top: 12px;

    .choost-prize-item {
      .vh-form-item__content {
        line-height: 1;

        .text-mute {
          height: 40px;
          line-height: 40px;
          vertical-align: middle;
        }
      }
    }
    .alert-noauth {
      padding: 0 0 0 8px;
      margin-top: 5px;
      width: 300px;
    }
    .btn-save {
      width: 96px;
      height: 40px;
      margin-top: 18px;
    }
  }

  .text-mute {
    color: #8c8c8c;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 8px 0;
    line-height: 24px;
  }
  .inline_input {
    width: 100px;
    /deep/ .vh-input-group__append {
      padding: 0 8px;
    }
  }
</style>
