<!-- 自定义button,适用于弹出选择对话框 -->
<template>
  <div class="choice-button-warp">
    <vh-button class="choice-button" :disabled="!hasPermission" v-bind="$attrs" v-on="$listeners">
      {{ label }}
    </vh-button>
    <span v-if="!hasPermission" class="not-auth">未开通权限</span>
  </div>
</template>
<script>
  export default {
    name: 'ChoiceButton',
    inheritAttrs: false,
    props: {
      hasPermission: {
        type: Boolean,
        required: true
      },
      label: {
        type: String,
        required: true
      }
    }
  };
</script>
<style lang="less">
  .choice-button-warp {
    .choice-button {
      display: inline-block;
      background: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 50px;
      font-size: 14px;
      color: #262626;
      text-align: center;
      cursor: pointer;
      padding: 12px 22px;

      .not-auth {
        font-size: 14px;
        color: #0099ff;
        margin-left: 6px;
      }

      &:hover {
        background: #fb3a32;
        color: #fff;
        border: 1px solid transparent;
      }
      &.is-disabled,
      &.is-disabled:focus,
      &.is-disabled:hover {
        background: #f0f0f0;
        color: #bfbfbf;
        border: 1px solid #f0f0f0;
      }
    }

    .not-auth {
      font-size: 14px;
      color: #0099ff;
      margin-left: 6px;
    }
  }
</style>
