<!-- 无需领奖 -->
<template>
  <div class="prize-receive-way item3">
    <!-- 表单区域 -->
    <div class="prize-receive-way__left">
      <vh-form label-width="100px">
        <vh-form-item>
          <div class="prize-receive-way__noneed">
            <span><img src="../../../MaterialSet/images/no-null.png" alt="" /></span>
            <p class="prize-receive-way__noneed-tip">用户无需领取礼品</p>
          </div>
        </vh-form-item>
        <vh-form-item label-width="38px">
          <vh-button
            type="primary"
            size="medium"
            round
            class="btn-save"
            @click="handleSubmit"
            v-preventReClick
          >
            保存
          </vh-button>
        </vh-form-item>
      </vh-form>
    </div>
    <!-- 预览区域 -->
    <div class="prize-receive-way__right">
      <div class="prize-receive-preview">
        <img
          style="width: 267px; height: 257px; margin: 0 auto; margin-top: 98px"
          src="../../../MaterialSet/images/no-award.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'WayItem3',
    data() {
      return {};
    },
    methods: {
      handleSubmit() {
        this.$emit('save', { data: JSON.stringify([]) });
      }
    }
  };
</script>
<style lang="less">
  .prize-receive-way.item3 {
    .prize-receive-way__noneed {
      width: 380px;
      height: 320px;
      background: rgba(0, 0, 0, 0.02);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &-tip {
        line-height: 20px;
        margin-top: 12px;
        font-size: 14px;
        text-align: center;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
</style>
