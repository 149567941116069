<!-- 互动有礼/礼品列表 -->
<template>
  <div class="vhc-container">
    <!-- 顶部搜索栏 -->
    <div class="vhc-search-bar" v-if="!isEmpty" style="margin-top: 20px">
      <vh-button
        size="medium"
        type="primary"
        round
        @click="handleAdd"
        :disabled="dataList && dataList.length >= 20"
      >
        创建礼品
      </vh-button>
      <vh-button
        size="medium"
        round
        plain
        type="default"
        class="transparent-btn"
        @click="handleMeterial"
      >
        资料库
      </vh-button>

      <vh-button
        size="medium"
        round
        plain
        @click="handleDelete()"
        :disabled="multipleSelection.length === 0"
      >
        批量删除
      </vh-button>
      <vh-button
        class="fr"
        size="medium"
        type="primary"
        round
        @click="saveSort"
        :disabled="!isSort"
      >
        保存排序
      </vh-button>
      <vh-input
        size="medium"
        round
        class="search-input"
        placeholder="请输入礼品标题"
        v-model="keyword"
        clearable
        v-clearEmoij
        @clear="handleSearch"
        @keyup.enter.native="handleSearch"
      >
        <i class="vh-icon-search vh-input__icon" slot="prefix" @click="handleSearch"></i>
      </vh-input>
    </div>

    <!-- 内容区 -->
    <div class="vhc-container__bd" v-loading="!isInited && loading">
      <div class="vhc-main" v-if="isInited">
        <!-- 没有数据时的展示 -->
        <div class="no-content" v-if="isEmpty">
          <noData class="no_data" :nullType="'nullData'" :text="'您还没有礼品，快来创建吧~'">
            <vh-button
              type="primary"
              round
              borderRadius="50"
              size="medium"
              v-preventReClick
              @click.prevent.stop="handleAdd"
            >
              创建礼品
            </vh-button>
            <vh-button
              round
              plain
              type="default"
              size="medium"
              borderRadius="50"
              v-preventReClick
              @click="handleMeterial"
            >
              资料库
            </vh-button>
          </noData>
        </div>
        <!-- 数据列表 -->
        <div class="vhc-main-content" v-if="!isEmpty">
          <vh-table
            ref="tableList"
            id="WRGiftListTable"
            :data="dataList"
            v-loading="loading"
            class="vhc-main-table"
            @selection-change="handleSelectionChange"
            @cell-mouse-enter="handleCellMouseEnter"
            @cell-mouse-leave="handleCellMouseLeave"
          >
            <vh-table-column type="selection" width="60" fixed="left" />
            <vh-table-column prop="award_img_url" label="礼品图片" width="100">
              <template slot-scope="scope">
                <div class="image-preview">
                  <vh-image
                    class="award_img"
                    fit="scale-down"
                    :src="scope.row.award_img_url"
                    :preview-src-list="[scope.row.award_img_url]"
                  ></vh-image>
                </div>
              </template>
            </vh-table-column>
            <vh-table-column prop="award_name" label="礼品名称" width="140">
              <template slot-scope="scope">
                <vh-tooltip
                  :disabled="!isTextOverflow"
                  effect="dark"
                  placement="top-start"
                  :content="scope.row.award_name"
                >
                  <div class="custom-tooltip-content">{{ scope.row.award_name }}</div>
                </vh-tooltip>
              </template>
            </vh-table-column>
            <vh-table-column prop="quantity" label="礼品数量"></vh-table-column>
            <vh-table-column prop="award_desc" label="礼品描述">
              <template slot-scope="scope">
                <vh-tooltip
                  :disabled="!isTextOverflow"
                  effect="dark"
                  placement="top-start"
                  :content="scope.row.award_desc"
                >
                  <div class="custom-tooltip-content">{{ scope.row.award_desc || '-' }}</div>
                </vh-tooltip>
              </template>
            </vh-table-column>
            <vh-table-column prop="create_time" label="创建时间" width="160">
              <template slot-scope="scope">
                <span>{{ scope.row.create_time | dateFormat('YYYY-MM-DD HH:mm') }}</span>
              </template>
            </vh-table-column>
            <vh-table-column label="操作" width="196">
              <template slot-scope="scope">
                <vh-button class="ctr_btn" type="text" size="tiny" @click="handleCopy(scope.row)">
                  <i class="icon-btn-item iconfont-v3 saasline-copy"></i>
                </vh-button>
                <vh-button
                  class="ctr_btn"
                  type="text"
                  size="tiny"
                  :disabled="scope.row.recipients_number > 0"
                  @click="handleEdit(scope.row)"
                >
                  <i class="icon-btn-item iconfont-v3 saasline-el-icon-edit"></i>
                </vh-button>
                <vh-button
                  class="ctr_btn"
                  type="text"
                  size="tiny"
                  :disabled="scope.row.recipients_number > 0"
                  @click="handleDelete(scope.row)"
                >
                  <i class="icon-btn-item iconfont-v3 saasline-delete"></i>
                </vh-button>
                <vh-button class="ctr_btn" type="text" size="tiny">
                  <i class="icon-btn-item iconfont-v3 saasline-rank"></i>
                </vh-button>
              </template>
            </vh-table-column>
            <template slot="empty">
              <noData :nullType="'search'" text="暂未搜索到您想要的内容"></noData>
            </template>
          </vh-table>
        </div>
      </div>
    </div>

    <!-- 创建/编辑礼品对话框 -->
    <AddOrEditReward
      ref="setPrize"
      :roomId="roomId"
      :webinarId="sharedData.webinarId"
      :watchRewardId="sharedData.watchRewardId"
      @completeEdit="completeEdit"
      @completeAdd="completeAdd"
    ></AddOrEditReward>

    <!-- 礼品选择对话框 -->
    <ChooseReward
      ref="ChooseReward"
      :source="1"
      :maxCount="200"
      @before-add="beforeRewardAdd"
      @completeSelection="completeRewardSelection"
    ></ChooseReward>
  </div>
</template>
<script>
  import noData from '@/views/PlatformModule/Error/nullPage';
  import to from 'await-to-js';
  import { sessionOrLocal, isEmbed, embedRoomId } from '@/utils/utils';
  import AddOrEditReward from '../dialog/AddOrEditReward.vue';
  import ChooseReward from '../dialog/ChooseReward.vue';
  import tableCellTooltip from '@/components/TableList/mixins/tableCellTooltip';
  import { debounce } from '@/utils/utils';
  import Sortable from 'sortablejs';
  export default {
    name: 'watcheRewardList',
    mixins: [tableCellTooltip],
    components: {
      noData,
      AddOrEditReward,
      ChooseReward
    },
    data() {
      return {
        isEmbed: isEmbed(),

        isInited: false, // 是否有成功的请求
        loading: false,
        isEmpty: true, // 是否完全空数据

        keyword: '', // 搜索关键字
        total: 0, // 总数量
        dataList: [], //数据集合
        multipleSelection: [], //选中的数据集合
        // 分页参数
        pagination: {
          pos: 0,
          limit: 20,
          pageNo: 1
        },
        roomId: this.$route.query.roomId,
        permissions: {},
        isSort: false
      };
    },
    inject: ['sharedDataFun', 'updateSharedDataFun'],
    computed: {
      sharedData() {
        return this.sharedDataFun();
      }
    },
    methods: {
      // 创建
      handleAdd() {
        if (this.dataList.length >= 20) {
          this.$message.error('最多只能创建20个礼品');
        }
        console.log('------handleAdd----');
        this.$refs.setPrize.reset();
        this.$refs.setPrize.dialogVisible = true;
      },
      // 资料库
      handleMeterial() {
        console.log('------handleMeterial----');
        const permissions = sessionOrLocal.get('SAAS_VS_PES', 'localStorage');
        if (permissions && JSON.parse(permissions)['ui.hide_lottery'] != 1) {
          this.$vhMessage.warning('当前无此权限');
          return;
        }
        this.$refs.ChooseReward.dialogVisible = true;
      },
      // 可选资源为空时，点击【创建】
      beforeRewardAdd() {
        // 延迟打开，避免上个对话框关闭时闪烁
        setTimeout(() => {
          this.$refs.setPrize.reset();
          this.$refs.setPrize.dialogVisible = true;
        }, 300);
      },
      // 完成礼品选择
      async completeRewardSelection(prizes = []) {
        const ids = prizes.map(item => item.prize_id).join(',');
        const params = {
          webinar_id: this.sharedData.webinarId,
          room_id: this.roomId,
          scenes: '1',
          award_ids: ids,
          lottery_id: this.sharedData.watchRewardId
        };
        const [err, res] = await to(this.$fetch('copyLibraryAward', params));
        if (err || res?.code != 200) {
          this.$message.error(err?.msg || res?.msg || '操作失败');
          return;
        }
        this.$vhMessage.success('选择成功');
        this.$refs.ChooseReward.dialogVisible = false;
        this.handleSearch();
      },
      // 复制某一条
      handleCopy(row) {
        if (this.dataList.length >= 20) {
          this.$message.error('最多只能创建20个礼品');
        }
        debounce(async () => {
          const params = {
            webinar_id: this.sharedData.webinarId,
            award_id: row.award_id
          };
          const [err, res] = await to(this.$fetch('copyWatchAward', params));
          if (err || res?.code != 200) {
            this.$message.error(err?.msg || res?.msg || '操作失败');
            return;
          }
          this.$vhMessage.success('复制成功');
          this.handleSearch();
        }, 500);
      },
      // 编辑某一条
      handleEdit(row) {
        console.log('------handleEdit--:', row);
        const ent = {
          ...row
        };
        this.$refs.setPrize.reInit(ent);
        this.$refs.setPrize.dialogVisible = true;
      },
      // 删除某一条/批量删除
      async handleDelete(row) {
        const confirm = await this.$vhConfirm(
          '删除后，当前礼品无法使用和查看，确认删除？',
          '提示',
          {
            roundButton: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            customClass: 'zdy-message-box',
            lockScroll: false,

            closeOnClickModal: false,
            closeOnPressEscape: false,
            cancelButtonClass: 'zdy-confirm-cancel'
          }
        ).catch(() => false);
        if (!confirm) return;
        const params = {
          webinar_id: this.sharedData.webinarId,
          room_id: this.roomId,
          scenes: '1',
          lottery_id: this.sharedData.watchRewardId,
          award_ids: row
            ? row.award_id
            : (this.multipleSelection.map(item => item.award_id) || []).join(',')
        };
        const [err, res] = await to(this.$fetch('deleteAward', params));
        if (err || res?.code != 200) {
          this.$vhMessage.error(err?.msg || res?.msg || '请求失败');
          return;
        }
        this.$vhMessage.success('删除成功');
        this.handleSearch();
      },
      // 编辑礼品完成
      completeEdit() {
        this.fetchData();
      },
      // 添加礼品完成
      completeAdd() {
        this.$vhMessage.success('创建成功');
        this.fetchData();
      },
      // 搜索
      handleSearch() {
        this.pagination.pos = 0;
        this.fetchData();
      },
      // 全选/部分选中/取消/部分取消时触发
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      // 翻页事件
      handleCurrentChange(current) {
        this.pagination.pageNo = current; //当前页码
        this.pagination.pos = parseInt((current - 1) * this.pagination.limit);
        this.fetchData();
      },
      // 每页条数改变时触发
      handleSizeChange(limit) {
        this.pagination.limit = limit;
        this.pagination.pageNo = 1;
        this.pagination.pos = 0;
        this.fetchData();
      },
      // 请求数据
      fetchData: async function () {
        this.loading = true;
        this.keyword = this.keyword.trim();
        const params = {
          keyword: this.keyword,
          limit: this.pagination.limit,
          pos: this.pagination.pos,
          room_id: this.roomId,
          webinar_id: this.sharedData.webinarId,
          watch_gift_id: this.sharedData.watchRewardId
        };
        const [err, res] = await to(this.$fetch('getWatchGiftList', params));
        this.loading = false;
        if (err || res?.code != 200) {
          this.$message.error(err?.msg || res?.msg || '请求数据失败');
          return;
        }
        this.isInited = true;
        this.total = res.data.total || 0;
        this.isEmpty = !this.total && this.keyword.length === 0;
        this.dataList = res.data.list;
        this.$nextTick(() => {
          this.setRowSort();
        });
      },
      setRowSort() {
        this.$nextTick(() => {
          const dom = document.querySelector('#WRGiftListTable  .vh-table__body-wrapper tbody');
          dom &&
            Sortable.create(dom, {
              ghostClass: '',
              handle: '.saasline-rank',
              onEnd: ({ newIndex, oldIndex }) => {
                if (newIndex != oldIndex) {
                  this.isSort = true;
                }
                this.dataList.splice(newIndex, 0, this.dataList.splice(oldIndex, 1)[0]);
                let newArray = this.dataList.slice(0);
                this.dataList = [];
                this.$nextTick(() => {
                  this.dataList = newArray;
                });
              }
            });
        });
      },
      async saveSort() {
        const award_sort_ids = this.dataList.map(el => {
          return el.award_id;
        });
        const [err, res] = await to(
          this.$fetch('sotWatchGift', {
            webinar_id: this.sharedData.webinarId,
            award_sort_ids: award_sort_ids.join(',')
          })
        );
        if (res.code == 200) {
          this.$message.success('排序保存成功');
        } else {
          this.$message.error(err?.msg || res?.msg);
        }
      }
    },
    mounted() {
      if (this.sharedData.watchRewardId) {
        this.fetchData();
      }
    }
  };
</script>
<style lang="less" scoped>
  .icon-btn-item {
    color: #595959;
    font-size: 20px;
    margin-right: 0;
  }
  .award_img {
    width: 80px;
    height: 80px;
    background: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
  }
  .ctr_btn {
    margin-right: 24px;
    margin-left: 0;
  }
  .vhc-main-content {
    min-height: auto !important;
  }
</style>
