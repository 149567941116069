<!-- 创建or编辑礼品： 有id的是走编辑，无id的走创建 -->
<template>
  <VhallDialog
    :title="`${isEdit ? '编辑' : '创建'}礼品`"
    :visible.sync="dialogVisible"
    v-if="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="468px"
  >
    <vh-form
      :model="form"
      :rules="rules"
      ref="prizeForm"
      label-width="80px"
      onsubmit="return false;"
    >
      <vh-form-item label="图片上传" required>
        <upload
          class="giftUpload"
          v-model="form.award_img_url"
          :domain_url="form.award_img_url"
          :on-success="prizeLoadSuccess"
          :on-progress="uploadProcess"
          :on-error="uploadError"
          :on-preview="uploadPreview"
          @delete="deleteImg"
          :widthImg="231"
          :heightImg="130"
          :before-upload="beforeUploadHandler"
        >
          <div slot="tip">
            <p>建议尺寸：240*240px，小于2M</p>
            <p>支持jpg、gif、png、bmp</p>
          </div>
        </upload>
      </vh-form-item>
      <vh-form-item label="礼品名称" prop="award_name">
        <vh-input
          v-model="form.award_name"
          :maxlength="10"
          autocomplete="off"
          show-word-limit
        ></vh-input>
      </vh-form-item>
      <vh-form-item label="礼品描述" prop="award_desc">
        <vh-input
          v-model="form.award_desc"
          :maxlength="70"
          autocomplete="off"
          type="textarea"
          :rows="4"
          show-word-limit
        ></vh-input>
      </vh-form-item>
      <vh-form-item label="礼品数量" prop="quantity">
        <vh-input
          type="number"
          v-model.number="form.quantity"
          autocomplete="off"
          show-word-limit
        ></vh-input>
      </vh-form-item>
    </vh-form>
    <span slot="footer">
      <vh-button type="info" size="medium" @click.prevent.stop="dialogVisible = false" plain round>
        取消
      </vh-button>
      <vh-button
        size="medium"
        type="primary"
        :loading="loading"
        @click="handleSubmit"
        round
        v-preventReClick
      >
        确定
      </vh-button>
    </span>
  </VhallDialog>
</template>
<script>
  import upload from '@/components/Upload/main';
  import noData from '@/views/PlatformModule/Error/nullPage';
  import { sessionOrLocal, isEmbed, embedRoomId } from '@/utils/utils';
  import { cloneDeep } from 'lodash';
  import useSharedMaterial from '@/components/MessageBox/useSharedMaterial.js';
  import to from 'await-to-js';

  export default {
    name: 'AddOrEditReward',
    components: {
      upload,
      noData
    },
    props: {
      watchRewardId: {
        type: [String, Number]
      },
      roomId: {
        type: [String, Number]
      },
      webinarId: {
        type: [String, Number]
      }
    },
    data() {
      return {
        isInWebinar: true,
        dialogVisible: false,
        loading: false,
        form: {
          award_id: '',
          award_img_url: '',
          award_name: '',
          award_desc: '',
          quantity: '',
          award_type: 0,
          scenes: '1'
        },
        rules: {
          award_name: [{ required: true, message: '请输入礼品名称', trigger: 'blur' }],
          quantity: [{ required: true, message: '请输入礼品数量', trigger: 'blur' }]
        },

        // 提示是否共享至资料管理对话框
        innerVisible: false,
        isShared: true
      };
    },
    computed: {
      isEdit() {
        return !!this.form.award_id;
      }
    },
    methods: {
      deleteImg() {
        this.form.award_img_url = '';
      },
      prizeLoadSuccess(res, file) {
        console.log(res, file);
        // this.form.imageUrl = URL.createObjectURL(file.raw);
        // this.form.img_path = res.data.file_url;
        this.form.award_img_url = res.data.domain_url;
        // this.fileList.push({
        //   url: this.form.imageUrl,
        //   cover: false
        // });
        // if (!this.fileList.some(item => item.cover)) {
        //   this.fileList[0].cover = true;
        // }
        // // 生成图片 ID 添加到 imgIdArr 中
        // this.generateImgId(this.form.imageUrl);
        // console.log(this.fileList);
      },
      beforeUploadHandler(file) {
        console.log(file);
        const typeList = ['png', 'jpeg', 'gif', 'bmp'];
        console.log(file.type.toLowerCase());
        let typeArr = file.type.toLowerCase().split('/');
        const isType = typeList.includes(typeArr[typeArr.length - 1]);
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isType) {
          this.$vhMessage.error(`上传礼品图片只能是 ${typeList.join('、')} 格式`);
          return false;
        }
        if (!isLt2M) {
          this.$vhMessage.error('上传礼品图片大小不能超过 2M');
          return false;
        }
        return isType && isLt2M;
      },
      uploadProcess(event, file, fileList) {
        console.log('uploadProcess', event, file, fileList);
      },
      uploadError(err, file, fileList) {
        console.log('uploadError', err, file, fileList);
        this.$vhMessage.error('礼品图片上传失败');
      },
      uploadPreview(file) {
        console.log('uploadPreview', file);
      },
      // 共享对话框确定
      handleShare() {},
      // 共享对话框取消
      handleCancelShare() {
        this.innerVisible = false;
      },
      reset() {
        this.form = {
          award_id: '',
          award_img_url: '',
          award_name: '',
          award_desc: '',
          quantity: '',
          award_type: 0,
          scenes: '1'
        };
      },
      reInit(ent) {
        this.form = cloneDeep(Object.assign({}, ent));
      },
      // 确定创建/编辑礼品
      async handleSubmit() {
        if (!this.form.award_img_url) {
          this.$vhMessage.error('请上传图片');
          return;
        }
        const [error, valid] = await to(this.$refs.prizeForm.validate());
        if (error || !valid) return;

        let isShared = false;
        if (!this.isEdit && !isEmbed()) {
          // 如果是创建 并且不是嵌入页，弹出是否共享到资料管理询问框
          isShared = await useSharedMaterial(this.$createElement, '礼品');
        }
        console.log('isShared:', isShared);
        //【观看有礼】 保存礼品
        const params = {
          webinar_id: this.webinarId, // 活动id
          room_id: this.roomId,
          share_to_library: Number(isShared), // 是否同步到资料库
          lottery_id: this.watchRewardId,

          ...this.form
        };
        this.loading = true;
        const [err, res] = await to(this.$fetch('saveLotteryAward', params));
        this.loading = false;
        if (err || res?.code != 200) {
          this.$message.error(err?.msg || res?.msg || '请求数据失败');
          return;
        }
        this.dialogVisible = false;
        if (this.isEdit) {
          // 编辑完成
          this.$emit('completeEdit', params);
        } else {
          // 创建完成
          const info = Object.assign({
            ...this.form,
            create_time: Date.now(),
            award_id: res.data?.id
          });
          this.$emit('completeAdd', info);
        }
      }
    }
  };
</script>
