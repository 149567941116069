<template>
  <div class="vhc-container vhc-container--InteractReward">
    <!-- 标题栏 -->
    <pageTitle :pageTitle="pageTitle"></pageTitle>

    <!-- 内容区 -->
    <div class="vhc-container__bd">
      <div class="vhc-main">
        <vh-tabs v-model="activeTab" @tab-click="handleChangeTab">
          <vh-tab-pane label="条件与礼品设置" name="1">
            <PrizeConditionSet ref="prizeConditionSet" v-if="activeTab == 1"></PrizeConditionSet>
          </vh-tab-pane>
          <vh-tab-pane label="领奖页设置" name="2" :disabled="!this.watchRewardId">
            <PrizeReceiveSet ref="prizeReceiveSet" v-if="activeTab == 2"></PrizeReceiveSet>
          </vh-tab-pane>
          <vh-tab-pane name="3" :disabled="!this.watchRewardId">
            <span slot="label">
              礼品设置
              <vh-tooltip effect="dark" placement="right" v-tooltipMove>
                <div slot="content">最多显示20个礼品，超过20个无法关联，需要将原有礼品删除</div>
                <i class="iconfont-v3 saasicon_help_m"></i>
              </vh-tooltip>
            </span>
            <PrizeListSet ref="prizeListSet" v-if="activeTab == 3"></PrizeListSet>
          </vh-tab-pane>
        </vh-tabs>
      </div>
    </div>

    <begin-play :webinarId="webinarId"></begin-play>
  </div>
</template>
<script>
  import PageTitle from '@/components/PageTitle';
  import PrizeConditionSet from './PrizeConditionSet.vue';
  import PrizeReceiveSet from './PrizeReceiveSet.vue';
  import PrizeListSet from './PrizeListSet.vue';
  import beginPlay from '@/components/beginBtn';

  export default {
    name: 'InteractiveRewardSetting',
    components: {
      PageTitle,
      PrizeConditionSet,
      PrizeReceiveSet,
      PrizeListSet,
      beginPlay
    },
    provide() {
      return {
        updateSharedDataFun: this.updateSharedData,
        sharedDataFun: () => {
          return {
            watchRewardId: this.watchRewardId,
            webinarId: this.webinarId,
            roomId: this.roomId
          };
        }
      };
    },
    data() {
      return {
        roomId: '',
        webinarId: '', //活动id
        watchRewardId: '', // 观看有礼id，编辑的时候才有值
        pageTitle: '',
        activeTab: '1'
      };
    },
    methods: {
      handleChangeTab() {
        this.$router.replace({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            tab: this.activeTab
          }
        });
        // if (this.watchRewardId) {
        //   if (this.activeTab === '1') {
        //     this.$refs.prizeConditionSet.fetchData();
        //   } else if (this.activeTab === '2') {
        //     this.$refs.prizeReceiveSet.fetchData();
        //   } else if (this.activeTab === '3') {
        //     this.$refs.prizeListSet.fetchData();
        //   }
        // }
      },
      updateSharedData(watchRewardId) {
        this.webinarId = this.$route.params.str || '';
        if (!watchRewardId) {
          this.watchRewardId = this.$route.query.wrId || '';
          this.roomId = this.$route.query.roomId || '';
        } else {
          this.watchRewardId = watchRewardId;
        }
        this.pageTitle = this.watchRewardId ? '编辑' : '创建';
      }
    },
    created() {
      this.updateSharedData();
      if (this.watchRewardId) {
        this.activeTab = this.$route.query.tab || '1';
      }
    }
  };
</script>
<style lang="less" scoped>
  .vhc-container--InteractReward {
    /deep/ .vh-tabs__content {
      height: 700px;
      overflow: auto;
    }
    /deep/.saasicon_help_m {
      color: #999999;
    }
  }
</style>
