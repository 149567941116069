<!-- 私信兑奖 -->
<template>
  <div class="prize-receive-way item2">
    <!-- 表单区域 -->
    <div class="prize-receive-way__left">
      <vh-form :model="privatePrizeForm" ref="ruleForm" label-width="100px">
        <vh-form-item label="私信号码">
          <vh-input
            v-model="privatePrizeForm.private_number"
            autocomplete="off"
            v-clearEmoij
            placeholder="请输入私信号码"
          ></vh-input>
        </vh-form-item>
        <vh-form-item label="二维码">
          <upload
            class="giftUpload"
            v-model="privatePrizeForm.private_qrcode"
            :domain_url="privatePrizeForm.private_qrcode"
            :heightImg="120"
            :on-success="privatePrizeLoadSuccess"
            :on-progress="uploadProcess"
            :on-error="uploadError"
            :on-preview="uploadPreview"
            @delete="deletePrivateImg"
            :before-upload="beforeUploadHandler"
          >
            <div slot="tip">
              <p>建议尺寸：240*240px，小于2M</p>
              <p>支持jpg、gif、png、bmp</p>
            </div>
          </upload>
        </vh-form-item>
        <vh-form-item label="提示说明">
          <vh-input
            v-model="privatePrizeForm.tip_note"
            autocomplete="off"
            v-clearEmoij
            :maxlength="20"
            placeholder="请截图中奖页面添加客服领取礼品哦 "
            show-word-limit
          ></vh-input>
        </vh-form-item>
        <vh-form-item label-width="38px">
          <vh-button
            type="primary"
            size="medium"
            round
            class="btn-save"
            :disabled="!(privatePrizeForm.private_qrcode || privatePrizeForm.private_number)"
            v-preventReClick
            @click="handleSubmit"
          >
            保存
          </vh-button>
        </vh-form-item>
      </vh-form>
    </div>
    <!-- 预览区域 -->
    <div class="prize-receive-way__right">
      <div class="prize-receive-preview">
        <div class="prize-receive-preview__popup">
          <div class="prize-receive-preview__popup-inner">
            <div class="reward-container__service">
              <div>
                <p class="service-title" v-if="privatePrizeForm.private_number">
                  {{ privatePrizeForm.private_number }}
                </p>
                <div class="reward-service" v-if="privatePrizeForm.private_qrcode">
                  <img :src="privatePrizeForm.private_qrcode" alt="" />
                </div>
                <p class="service-title" v-if="privatePrizeForm.tip_note">
                  {{ privatePrizeForm.tip_note }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import upload from '@/components/Upload/main';
  export default {
    name: 'WayItem2',
    components: {
      upload
    },
    data() {
      return {
        backgroundImg: '',
        previewSrc: null,
        isChecked: 1,
        privatePrizeForm: {
          tip_note: '请截图中奖页面添加客服领取礼品哦 ',
          private_qrcode: '',
          private_number: ''
        }
      };
    },
    methods: {
      reInit(defa) {
        // console.log('defa:', defa);
        if (Array.isArray(defa?.data)) {
          for (const item of defa?.data) {
            this.privatePrizeForm[item.field_key] = item.placeholder;
          }
        }
      },
      prizeLoadSuccess(res, file) {
        console.log('图片上传', res, 'ssssss', file);
        this.previewSrc = res.data.domain_url;
        this.isChecked = 0;
        this.backgroundImg = res.data.domain_url;
      },
      privatePrizeLoadSuccess(res, file) {
        console.log('图片上传', res, 'ssssss', file);
        this.privatePrizeForm.private_qrcode = res.data.domain_url;
      },
      deletePrivateImg() {
        this.privatePrizeForm.private_qrcode = '';
      },
      beforeUploadHandler(file) {
        console.log(file);
        const typeList = ['png', 'jpeg', 'gif', 'bmp'];
        console.log(file.type.toLowerCase());
        let typeArr = file.type.toLowerCase().split('/');
        const isType = typeList.includes(typeArr[typeArr.length - 1]);
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isType) {
          this.$vhMessage({
            message: `文件格式不支持，请检查图片`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        if (!isLt2M) {
          this.$vhMessage({
            message: `文件大小超过2M，请检查图片`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        return isType && isLt2M;
      },
      uploadProcess(event, file, fileList) {
        console.log('uploadProcess', event, file, fileList);
      },
      uploadError(err, file, fileList) {
        console.log('uploadError', err, file, fileList);
        this.$vhMessage({
          message: `图片上传失败`,
          showClose: true,
          // duration: 0,
          type: 'error',
          customClass: 'zdy-info-box'
        });
      },
      uploadPreview(file) {
        console.log('uploadPreview', file);
      },
      // 【私信兑奖】 设置保存
      handleSubmit() {
        // TODO: 是否需要校验？？
        const payload = {
          receive_award_field: this.getCustomFormJsonstr()
        };
        this.$emit('save', payload);
      },
      getCustomFormJsonstr() {
        return JSON.stringify([
          {
            is_system: 1,
            field: '私信号码',
            field_key: 'private_number',
            is_required: false,
            placeholder: this.privatePrizeForm.private_number,
            rank: 1
          },
          {
            is_system: 1,
            field: '二维码',
            field_key: 'private_qrcode',
            is_required: false,
            placeholder: this.privatePrizeForm.private_qrcode,
            rank: 2
          },
          {
            is_system: 1,
            field: '提示说明',
            field_key: 'tip_note',
            is_required: true,
            placeholder: this.privatePrizeForm.tip_note,
            rank: 3
          }
        ]);
      }
    }
  };
</script>
<style lang="less">
  .prize-receive-preview__popup {
    margin: auto;
    margin-top: 227px;
    width: 276px;
    height: 279px;
    margin-left: 0;
    background-image: url('../../../../../common/images/gif/accept-review.png');
    background-size: 100%;
    border-radius: 0 0 24px 24px;

    &-inner {
      padding-top: 45px;
    }

    .reward-container__service {
      text-align: center;
      width: 248px;
      height: 212px;
      background: #fff;
      border-radius: 4px;
      margin: 10px auto;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      .reward-service {
        width: 120px;
        height: 120px;
        margin: 0 auto;
        margin-bottom: 5px;
        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
        }
      }
      .service-title {
        width: 218px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
        line-height: 32px;
        height: 32px;
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
</style>
